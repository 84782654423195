.newsletter-option {
  transition: all 0.3s ease;
  a, a:visited {
    border-color: $primary !important;
  }
  .newsletter-text {
    width:70%;
  }
}

.newsletter-option:has(:checked) {
  background: $dark !important;
  color: $white !important;
  transition: all 0.3s ease;
  border-color: $success-on-dark !important;
  a, a:visited {
    border-color: $success-on-dark !important;
  }
}


@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .newsletter-option {
    .newsletter-text {
      width:100%;
    }
  }
}
