.taa-breadcrumb-1 {
  a, a:visited {
    @extend .h1;
    @extend .huge;
    margin-bottom: 0 !important;
    color: $dark;
    opacity: 0.15;
    transition: opacity 0.3s ease;
    text-decoration: none;
    &:hover, &:focus {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
}

.taa-breadcrumb-2 {
  margin-left: 16.6666666667%;
  padding-left: 10px;
  margin-top: -2em;

  a, a:visited {
    @extend .h2;
    margin-bottom: 0 !important;
    @extend .font-weight-bold;
    color: $primary;
    text-decoration: none;
    &:hover, &:focus {
      color: darken($primary, 10%);
      &:before {
        transform: rotate(180deg);
        transition: all 0.3s ease;
      }
    }
    position:relative;
    &:before {
      content: "";
      position: absolute;
      width: 4rem;
      height: 4rem;
      background: url(#{$image-path}icons/back_arrow.svg) center center no-repeat;
      background-size: contain;
      display:block;
      left: -5rem;
      top: -2rem;
      @extend .filter-primary !optional;
      z-index: -4;
      transition: all 0.3s ease;
    }
  }
}

.taa-breadcrumb-3 {
  margin-left: 16.6666666667%;
  padding-left: 10px;

  position:relative;
  display:flex;
  align-items: center;
  margin-top: 0.5rem;

  a, a:visited {
    background-color: $light;
    font-weight: bold;
    text-decoration: none;
    display:inline-flex;
    position:relative;
    z-index: 20;
    padding: 0.3rem 0.6rem;
    color: $dark;

    @extend .smallish;

    img {
      @extend .filter-primary;
      margin-right: 0.5rem;
      transition: all 0.3s ease;
    }
    &:hover, &:focus {
      color: $dark;
      background-color: $highlight;
      img {
        @extend .filter-dark;
        transition: all 0.3s ease;
      }
    }

  }
}

#h1, #taa-breadcrumb-1-link, #taa-breadcrumb-2-link, #taa-breadcrumb-3-link {
  transition: all 0.3s ease;
}

body:has(#taa-breadcrumb-1-link:hover) {
  #h1, #taa-breadcrumb-2-link, #taa-breadcrumb-3-link {
    opacity: 0.2;
    transition: all 0.3s ease;
  }
}

body:has(#taa-breadcrumb-2-link:hover) {
  #h1, #taa-breadcrumb-3-link {
    opacity: 0.2;
    transition: all 0.3s ease;
  }
}

body:has(#taa-breadcrumb-3-link:hover) {
  #h1, #taa-breadcrumb-2-link {
    opacity: 0.2;
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
  .taa-breadcrumb-2, .taa-breadcrumb-3 {
    margin-left: 8.3333333333%;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .taa-breadcrumb-2 a:before {
    width: 2rem;
    height: 2rem;
    left: -2.5rem;
    top: -0.8rem;
  }
}


.headline-with-icon {
  display: flex;
  img {
    height: 1em;
    margin-left: -1.2em;
    margin-right: 0.2em;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .headline-with-icon img {
    margin-left: 0;
  }
}

.back-headline {
  position: relative;
  margin-left: 16.6666666667%;
  padding-left: 10px;
  margin-top: -2.9em;
  h1 {
    @extend .h1;
   @extend .bigger !optional;
    @extend .font-weight-bold;
  }

  #back-icon-link, #back-icon-link:visited {
    content: "";
      position: absolute;
      img {
        width: 4rem;
        height: 4rem;
        @extend .filter-primary;
      }
      display:block;
      left: -5rem;
      top: -2rem;
      margin-left: 10px;
      z-index: -4;
      transition: all 0.3s ease;
  }
}
