.timeline {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 7%;
    width: 2px;
    height: 100%;
    background: $primary;
  }
  .timeline-entry:last-of-type {
    background: $white;
    z-index:100;
    position:relative;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .timeline {
    &:before {
      display:none;
  }
}
}