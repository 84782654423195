
$primary:#D60B52;
$filter-primary: brightness(0) saturate(100%) invert(14%) sepia(62%) saturate(7422%) hue-rotate(332deg) brightness(85%) contrast(96%) !default;

$primary-on-dark: #F08597;
$filter-primary-on-dark: brightness(0) saturate(100%) invert(75%) sepia(20%) saturate(3461%) hue-rotate(301deg) brightness(102%) contrast(88%) !default;

$body-bg: #FAF9F8;

@import "~taa-frontend-assets/taa";
@import "stylesheets/_ui_workarounds";
@import "stylesheets/_local_the-audience-agency";

