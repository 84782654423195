$indicator-size: 9rem;

.big-num-box-list {
  padding-left:0;
  counter-reset: list-counter;
  list-style-type: none;
  li {
    display: flex;
    @extend .my-4;
    counter-increment: list-counter;
    a, a:visited, .item {
      background-color: $more-white;
      @extend .py-4;
      @extend .pr-4;
      width:100%;
      align-items:center;
      @extend .border;
      @extend .shadow;
      text-decoration:none;
      color: $dark;
      padding-left: $indicator-size;
      transition: all 0.3s ease;
      img {
        max-width: 17rem;
      }
      &:before {
        content: counter(list-counter);
        color: $white;
        @include font-size(2.625rem);
        font-weight: $font-weight-bold;
        position: absolute;
        left: 0;
        line-height: $indicator-size;
        width: $indicator-size;
        height: $indicator-size;
        top: -1.5rem;
        background: $dark;
        text-align: center;
        border-radius:50%;
        transition: all 0.3s ease;
      }
    }
    a, a:visited {
      &:hover, &:focus {
        background-color: $gray-50;
        transition: all 0.3s ease;
        text-decoration: none !important;
        &:before {
          background-color: $highlight;
          color: $dark;
          transition: all 0.3s ease;
        }
      }
    }
  }

}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  $indicator-size: 6rem;
  .typography .big-num-box-list, .big-num-box-list {
    li {
      margin-top: $indicator-size !important;
      a, a:visited, .item {
        padding-top: 1.5rem !important;
        padding-left: 1.5rem !important;
        &:before {
          top: -5rem;
          line-height: $indicator-size;
          width: $indicator-size;
          height: $indicator-size;
          left: 0.5rem;
        }
      }
    }
  }
}
