.random-circles-container {
  position:relative;
  display:block;
  padding-bottom: 100%;
  .random-circle {
    position:absolute;
    top:0;
    left:0;
    display:block;
  }
  #circle-1 {
    left:32%;
    top:5%;
    width:21%;
  }
  #circle-2 {
    right:3%;
    left:auto;
    width:31%;
  }
  #circle-3 {
    left:8%;
    top:29%;
    width:10%;
  }
  #circle-4 {
    left:23%;
    top:28%;
    width:23%;
  }
  #circle-5 {
    left:50%;
    top:25%;
    width:16%;
  }
  #circle-6 {
    left:5%;
    top:45%;
    width:15%;
  }
  #circle-7 {
    left:43%;
    top:49%;
    width:17%;
  }
  #circle-8 {
    left:auto;
    right:12%;
    top:38%;
    width:24%;
  }
  #circle-9 {
    left:0;
    top:65%;
    width:15%;
  }
  #circle-10 {
    left:20%;
    top:60%;
    width:21%;
  }
  #circle-11 {
    left:48%;
    top:70%;
    width:25%;
  }
  #circle-12 {
    left:auto;
    right:0;
    top:65%;
    width:23%;
  }
  #circle-13 {
    left:30%;
    top:85%;
    width:15%;
  }
}

.random-circles-container-horizontal {
  position:relative;
  display:block;
  padding-bottom: 39%;
  .random-circle {
    position:absolute;
    top:0;
    left:0;
    display:block;
    width:0%;
  }
  #circle-1 {
    left:10%;
    top:0;
    width:6.4%;
  }
  #circle-2 {
    left:0;
    top:15%;
    width:9.4%;
  }
  #circle-3 {
    left:2%;
    top:46%;
    width:17%;
  }
  #circle-4 {
    left:15%;
    top:22%;
    width:8.7%;
  }
  #circle-5 {
    left:27%;
    top:17%;
    width:11.6%;
  }
  #circle-6 {
    left:22%;
    top:55%;
    width:12.8%;
  }
  #circle-7 {
    left:38%;
    top:5%;
    width:5.7%;
  }
  #circle-8 {
    left:36%;
    top:45%;
    width:9%;
  }
  #circle-9 {
    left:34%;
    top:82%;
    width:5.7%;
  }
  #circle-10 {
    left:45%;
    top:12%;
    width:17.5%;
  }
  #circle-11 {
    left:45%;
    top:65%;
    width:13.5%;
  }
  #circle-12 {
    left:62%;
    top:55%;
    width:8.2%;
  }
  #circle-13 {
    left:66%;
    top:20%;
    width:11.8%;
  }
  #circle-14 {
    left:73%;
    top:53%;
    width:13.2%;
  }
  #circle-15 {
    left:82%;
    top:15%;
    width:13.9%;
  }
  #circle-16 {
    left:auto;
    right: 0;
    top:60%;
    width:8.6%;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .random-circles-container-horizontal {
    
  padding-bottom: 100%;
 
  #circle-1 {
    left:32%;
    right:auto;
    top:5%;
    width:21%;
  }
  #circle-2 {
    right:3%;
    left:auto;
    width:31%;
    top:0;
  }
  #circle-3 {
    left:8%;
    right:auto;
    top:29%;
    width:10%;
  }
  #circle-4 {
    left:23%;
    right:auto;
    top:28%;
    width:23%;
  }
  #circle-5 {
    left:50%;
    right:auto;
    top:25%;
    width:16%;
  }
  #circle-6 {
    left:5%;
    right:auto;
    top:45%;
    width:15%;
  }
  #circle-7 {
    left:43%;
    right:auto;
    top:49%;
    width:17%;
  }
  #circle-8 {
    left:auto;
    right:12%;
    top:38%;
    width:24%;
  }
  #circle-9 {
    left:0;
    right:auto;
    top:65%;
    width:15%;
  }
  #circle-10 {
    left:20%;
    right:auto;
    top:60%;
    width:21%;
  }
  #circle-11 {
    left:48%;
    right:auto;
    top:70%;
    width:25%;
  }
  #circle-12 {
    left:auto;
    right:0;
    top:65%;
    width:23%;
  }
  #circle-13 {
    left:30%;
    right:auto;
    top:85%;
    width:15%;
  }
  #circle-14 {
    left:10%;
    right:auto;
    top:7%;
    width:16%;
  }
  #circle-15 {
    top:82%;
    left:12%;
    right:auto;
    width: 10%;
  }
  #circle-16 {
    top:32%;
    left:auto;
    right:0;
    width: 10%;
  }
}
}