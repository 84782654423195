.newsletter-footer {
  background-image: url(#{$image-path}backgrounds/newsletter-footer-background.svg);
  background-repeat: no-repeat;
  background-attachment: scroll; 
  background-size: 100% 101%;
  h2 {
    transform: rotate(-15deg);
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .newsletter-footer {
    background-size: auto 101%;
  }
}

.news-text {
  background: $dark;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  color: $white;
  display: inline;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  
  @include font-size(2.4rem);
  font-family: $font-family-base;
  line-height: 1.5;
  transition: all 0.3s ease;
}

a:hover, a:focus {
  .news-text {
    background: $highlight;
    color: $dark;
    transition: all 0.3s ease;
  }
}