.subnav {
  @extend .list-unstyled;
  position:relative;
  &:before {
    content: "";
    display:block;
    position:absolute;
    top:0.3rem;
   
    left:-4rem;
    width: 3rem;
    height: 2rem;
    background: url(#{$image-path}icons/next.svg) center center no-repeat;
    background-size: contain;
  }
  li {
    display: inline-block;
    margin-right: 0.6em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
  a, a:visited {
    background: $primary;
    color: $white;
    @extend .px-2;
    @extend .py-1;
    display: block;
    text-decoration:none;
    font-weight: bold;
    transition: all 0.2s ease;
    &:hover, &:focus {
      background: $highlight;
      color: $dark;
      transition: all 0.2s ease;
    }
  }
}

.filters {
  @extend .list-unstyled;
  position:relative;
  li {
    display: inline-block;
    margin-right: 0.6em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }
  a, a:visited {
    background: $light;
    color: $primary;
    @extend .px-2;
    @extend .py-1;
    display: block;
    text-decoration:none;
    font-weight: bold;
    transition: all 0.2s ease;
    &:hover, &:focus {
      background: $highlight;
      color: $dark;
      transition: all 0.2s ease;
    }
  }
  .active {
    a, a:visited {
      background: $highlight;
      color: $dark;
    }
  }
  li:not(:has(a)) {
    background: $light;
    color: $dark;
    @extend .px-2;
    @extend .py-1;
    display: inline-block;
  }
}

