@import "local_the-audience-agency/_subnav";
@import "local_the-audience-agency/_big-num-box-list";
@import "local_the-audience-agency/_taa-page-top";
@import "local_the-audience-agency/_random-circles";
@import "local_the-audience-agency/_timeline";
@import "local_the-audience-agency/_newsletter-footer";
@import "local_the-audience-agency/_newsletter-sign-up";
@import "local_the-audience-agency/_taa-home";


.flexible-content-block--one-column {
  .row-smart-cols {
    @extend .row-cols-1;
    @extend .row-cols-md-2;
    @extend .row-cols-lg-3;
  }
  .row-smart-cols-2 {
    @extend .row-cols-1;
    @extend .row-cols-lg-2;
  }
}

.flexible-content-block--two-col-wide-left .col-lg-7, .flexible-content-block--two-col-wide-right .col-lg-7, .col-md-7 {
  .row-smart-cols {
    @extend .row-cols-1;
    @extend .row-cols-lg-2;
  }
  .row-smart-cols-2 {
    @extend .row-cols-1;
  }
}

.flexible-content-block--two-col-wide-left .col-lg-4, .flexible-content-block--two-col-wide-right .col-lg-4 {
  .row-smart-cols {
    @extend .row-cols-1;
    @extend .row-cols-md-2;
    @extend .row-cols-lg-1;
  }
  .row-smart-cols-2 {
    @extend .row-cols-1;
  }
}


// Hide the exposed form on our projects and peoople to make the manual filter styling easier.
#views-exposed-form-projects-block-main-block{
  display: none;
}
#views-exposed-form-people-block-main-block{
  display: none;
}
