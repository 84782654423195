$gap: 2%;
$width-1: 5%;
$width-2: 7.5%;
$width-3: 10.5%;
$width-4: 15.5%;
$width-5: 22.5%;
$width-6: 27.5%;
$animation-delay-step: 0.2s;

.home-top {
  overflow-x: hidden;
  .big-home-intro {
    opacity: 0;
    animation: fadeIn 1s ease-in $animation-delay-step * 3 1 forwards;
  }
  .block-arrow-link {
    opacity: 0;
    animation: fadeIn 1s ease-in $animation-delay-step * 6 1 forwards;
  }
}

.home-person-container {
  width: 60%;
  margin-left:45%;
  position:relative;
  padding-bottom: 5%;
  .home-person-img {
    position: absolute;
    bottom:0;
    left:0;
    width: 0%;
    opacity: 0;
    animation: fadeIn 1s ease-in 0s 1 forwards;
  }
  #home-person-img-1 {
    width: $width-1;
  }
  #home-person-img-2 {
    width: $width-2;
    left: $width-1 + $gap;
    animation-delay: $animation-delay-step;
  }
  #home-person-img-3 {
    width: $width-3;
    left: $width-1 + $gap + $width-2 + $gap;
    animation-delay: $animation-delay-step * 2;
  }
  #home-person-img-4 {
    width: $width-4;
    left: $width-1 + $gap + $width-2 + $gap + $width-3 + $gap;
    animation-delay: $animation-delay-step * 3;
  }
  #home-person-img-5 {
    width: $width-5;
    left: $width-1 + $gap + $width-2 + $gap + $width-3 + $gap + $width-4 + $gap;
    animation-delay: $animation-delay-step * 4;
  }
  #home-person-img-6 {
    width: $width-6;
    left: $width-1 + $gap + $width-2 + $gap + $width-3 + $gap + $width-4 + $gap + $width-5 + $gap;
    animation-delay: $animation-delay-step * 5;
  }
}

.rest-of-page {
  opacity: 0;
  animation: fadeIn 1s ease-in $animation-delay-step * 8 1 forwards;
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
  .home-person-container {
    width: 80%;
    margin-left:25%;
    padding-bottom: 15%;
  }
}

@media screen and (max-width: map-get($grid-breakpoints, "md")) {
  .home-person-container {
    width: 100%;
    margin-left:5%;
    padding-bottom: 20%;
  }
}